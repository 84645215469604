






















































import PaymentModel from '@/models/Payment.model';
import { setterAmount, setterDate } from '@/plugins/utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class ListRowPayment extends Vue {
  @Prop({ required: true, type: PaymentModel })
  payment!: PaymentModel;

  @Prop({ default: false, type: Boolean })
  showEmail!: boolean;

  setDate = (dater: Date) => setterDate(dater);
  setAmount = (amount: number) => setterAmount(amount);
}
